import React, { memo, ReactElement } from 'react'
import './NewsLetterTitle.scss'

const addStyleTags = (title: string): string =>
  title.replace(/(and|latest|get)\s(\w+)/g, '$1 <span>$2</span>')

type NewsLetterTitlePropTypes = {
  title: string
}

const NewsLetterTitle = ({ title }: NewsLetterTitlePropTypes): ReactElement => {
  return (
    <p
      className="newsletter-title"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: addStyleTags(
          'Sign up to get updates on latest news and promos',
        ),
      }}
    />
  )
}

export default memo(NewsLetterTitle)
