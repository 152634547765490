import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  memo,
} from 'react'
import './Footer.scss'
import { graphql, Link, useStaticQuery } from 'gatsby'
import AccessAyalaImg from '../../assets/images/access ayala/Footer banner A - Green Copy.png'
import AccessAyalaImgMobile from '../../assets/images/access ayala/Footer banner short -  Green Copy-1.png'
import AccessAyalaImgTablet from '../../assets/images/access ayala/Footer banner short -  Green Copy-2.png'

import avidaIconWhite from '../../assets/svg/avida-logo-white.svg'
import fbIcon from '../../assets/svg/fb.svg'
import instaIcon from '../../assets/svg/insta.svg'
import youtubeIcon from '../../assets/svg/yt.svg'

import navItems from '../../data/footer-menu.json'

import FooterLinkNavigation from '../FooterLinkNavigation/FooterLinkNavigation'
import NewsLetterTitle from '../NewsLetterTitle/NewsLetterTitle'
import CookiePolicy from '../CookiePolicy/CookiePolicy'
import { toKebabCase } from '../../services/caseTransform'
import ThirdPartyForm from '../ThirdPartyForm/ThirdPartyForm'

export type SubscribeStatusTypes = 'success' | null

type NewsletterTypes = {
  title: string
  featuredImage?: string
}

type LinkTypes = {
  url: string
}

type FooterQueryTypes = {
  newsletter: NewsletterTypes
  facebook?: LinkTypes
  instagram?: LinkTypes
  youtube?: LinkTypes
  allShowroom: {
    nodes: {
      showRoomTitle: string
    }[]
  }
}

type FooterPropTypes = {
  includeNewsLetter?: boolean
}

const Footer = ({ includeNewsLetter }: FooterPropTypes): ReactElement => {
  const {
    newsletter: newsletterData,
    facebook,
    instagram,
    youtube,
    allShowroom: { nodes: allShowroom },
  }: FooterQueryTypes = useStaticQuery(graphql`
    query FooterQuery {
      newsletter {
        title
        featuredImage
      }
      facebook: link(
        category: { eq: "social_media" }
        name: { eq: "facebook" }
      ) {
        ...LinkFields
      }
      instagram: link(
        category: { eq: "social_media" }
        name: { eq: "instagram" }
      ) {
        ...LinkFields
      }
      youtube: link(category: { eq: "social_media" }, name: { eq: "youtube" }) {
        ...LinkFields
      }
      allShowroom(filter: { type: { eq: "" } }) {
        nodes {
          showRoomTitle
        }
      }
    }
  `) || {
    newsletter: {
      title: 'Be the first to know',
    },
  }
  const [subscribeStatus, setSubscribeStatus] = useState<SubscribeStatusTypes>(
    null,
  )

  useEffect(() => {
    if (subscribeStatus === 'success') {
      setTimeout((): void => {
        setSubscribeStatus(null)
      }, 1000)
    }
  }, [subscribeStatus])

  const handleSubmit = useCallback((): void => {
    setSubscribeStatus('success')
  }, [])

  const initialShowroom = allShowroom[0]
  const showroomUrl = toKebabCase(initialShowroom.showRoomTitle)

  return (
    <>
      {includeNewsLetter && (
        <section className="newsletter-section">
          <div className="newsletter-container">
            <div className="newsletter-header">
              <p className="newsletter-header-text">Newsletter</p>
              {newsletterData?.featuredImage ? (
                <img
                  src={newsletterData.featuredImage}
                  className="newsletter-header-image"
                  alt="newsletter"
                />
              ) : (
                <NewsLetterTitle title={newsletterData.title} />
              )}
            </div>
            <div className="newsletter-input-container">
              {subscribeStatus === 'success' ? (
                <p>Thank you for subscribing to Avida newsletter!</p>
              ) : (
                <ThirdPartyForm
                  region="na1"
                  id="newsletter-subscription-form"
                  portalId="4090950"
                  formId="51d8b223-8542-46b5-9e63-008051b6cc4f"
                  onFormSubmit={handleSubmit}
                  onFormReady={(): null => {
                    return null
                  }}
                />
              )}
            </div>
          </div>
        </section>
      )}

      <section className="footer-section">
        <Link
          to="https://access.ayalaland.com/"
          className="access-ayala-footer"
        >
          <img src={AccessAyalaImg} alt="" className="access-ayala-image" />
          <img
            src={AccessAyalaImgMobile}
            className="access-ayala-image-mobile"
            alt="Access Ayala Mobile"
          />
          <img
            src={AccessAyalaImgTablet}
            className="access-ayala-image-tablet"
            alt="Access Ayala Tablet"
          />
        </Link>
        <div
          className="footer-section-content"
          data-testid="footer-section-content"
        >
          <div className="footer-background-image" />
          <div className="footer-avida-container">
            <div>
              <img
                className="footer-avida-icon"
                src={avidaIconWhite}
                alt="avidaIconWhite"
              />
              <div className="footer-avida-content">
                Celebrate life everyday with Avida, and experience a lifestyle
                that is easy, distinct, and enriching.
              </div>
              <div className="footer-avida-content">
                With over three decades of providing sure, secure, and sensible
                homes in sustainable communities nationwide, Avida has become
                the developer of choice among the rising middle class—making
                dream spaces a reality while offering more than the essentials.{' '}
              </div>
              <div className="footer-social-container">
                {!!facebook && (
                  <a
                    href={facebook.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-social-facebook"
                      src={fbIcon}
                      alt="fbIcon"
                    />
                  </a>
                )}
                {!!instagram && (
                  <a
                    href={instagram.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-social-instagram"
                      src={instaIcon}
                      alt="instaIcon"
                    />
                  </a>
                )}
                {!!youtube && (
                  <a
                    href={youtube.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-social-youtube"
                      src={youtubeIcon}
                      alt="youtubeIcon"
                    />
                  </a>
                )}
              </div>
            </div>
            <div>
              <div className="footer-agreement">
                <Link to="/privacy-notice/" className="footer-agreement-links">
                  Privacy Notice
                </Link>
                <span className="dot-separator">•</span>
                <Link
                  to="/terms-and-conditions/"
                  className="footer-agreement-links"
                >
                  Terms and Conditions
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-details-container">
            <div className="hotline-container">
              <h3>Get In Touch</h3>
              <div>
                <p className="hotline-container-details">
                  You may get in touch with us through the contact numbers
                  below:
                </p>
                <p className="hotline-container-details">
                  Customer Service Hotline:
                </p>
                <p className="hotline-container-numbers">(+632) 8848-5000</p>
              </div>
              <div>
                <p className="hotline-container-details">
                  Head Office Trunk line:
                </p>
                <p className="hotline-container-numbers">(+632) 89882111</p>
              </div>
              <div>
                <p className="hotline-container-link">
                  <Link to={`/contact-us/showroom/${showroomUrl}/`}>
                    Click here for our offices & showrooms
                  </Link>
                </p>
              </div>
            </div>
            <div className="footer-navlinks">
              <h3>Useful Links</h3>
              <FooterLinkNavigation />
              <div className="footer-navlinks-container">
                <div className="footer-group">
                  {navItems.slice(0, 4).map((navItem) => {
                    return (
                      <div key={navItem.name}>
                        <Link to={`${navItem.url}`}>{navItem.name}</Link>
                      </div>
                    )
                  })}
                </div>
                <div className="footer-group">
                  {navItems.slice(4, 8).map((navItem) => {
                    return (
                      <div key={navItem.name}>
                        <Link to={`${navItem.url}`}>{navItem.name}</Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-subfooter">
          <div
            className="section-subfooter-container"
            data-testid="section-subfooter-container"
          >
            <p>©2020 AVIDALAND</p>
            <p>
              Powered by
              <a
                href="https://whitecloak.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                White Cloak Technologies Inc.
              </a>
            </p>
          </div>
        </div>
      </section>
      <CookiePolicy />
    </>
  )
}

export default memo(Footer)
