import React, { memo, ReactElement, useEffect } from 'react'
import './ThirdPartyForm.scss'

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create(option: {
          region?: string
          portalId: string
          formId: string
          target: string
          onFormReady: () => void
          onFormSubmit: () => void
        }): void
      }
    }
    jQuery?: {}
  }
}

type ThirdPartyFormPropTypes = {
  region?: string
  id: string
  portalId: string
  formId: string
  onFormReady: () => void
  onFormSubmit: () => void
}

const ThirdPartyForm = ({
  region,
  id,
  portalId,
  formId,
  onFormReady,
  onFormSubmit,
}: ThirdPartyFormPropTypes): ReactElement => {
  useEffect(() => {
    window.jQuery =
      window.jQuery ||
      ((): { change(): null; trigger(): null } => ({
        change: (): null => null,
        trigger: (): null => null,
      }))

    if (window?.hbspt) {
      const currentForm: any = {
        portalId,
        formId,
        target: `#${id}`,
        onFormReady,
        onFormSubmit,
      }

      if (region) {
        currentForm.region = region
      }

      window.hbspt.forms.create(currentForm)
    }
  }, [region, id, formId, portalId, onFormSubmit, onFormReady])

  return <div id={id} className="third-party-form" />
}

export default memo(ThirdPartyForm)
